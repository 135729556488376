import EBMmem_1_Img from '../../resources/Members/DR_B_K_Dutta.jpeg';
import EBMmem_2_Img from '../../resources/Members/Dr_Biswabina_Ray.jpg';
import EBMmem_3_Img from '../../resources/Members/Dr_Nidhi_Puri.jpg';
import EBMmem_4_Img from '../../resources/Members/Dr_Simmi_Mehra.jpg';
import EBMmem_5_Img from '../../resources/Members/Dr_Prarabdha_Agrawal.jpg';
import EBMmem_6_Img from '../../resources/Members/Dr_R_Sarah.jpg';
import EBMmem_7_Img from '../../resources/Members/Dr_Anjali_Aggrawal.jpg';
import EBMmem_8_Img from '../../resources/Members/Dr_Manisha_R_Gaikwad.jpg';
import EBMmem_9_Img from '../../resources/Members/Dr_Priti_Chaudhary.jpg';
import EBMmem_10_Img from '../../resources/Members/Dr_Seema_Singh.jpg';
import EditorInChief_Img from '../../resources/Members/EditorInChief.jpeg';
import DummyImg from '../../resources/Members/dummy.jpg';

export const boardMembersData = [
    {
        id: 1,
        imageSrc: EditorInChief_Img,
        BoardDes: 'Editor-in-Chief',
        name: 'Dr. Yogesh A. Sontakke',
        degree: 'MBBS MD (Anatomy)',
        position: 'Additional Professor',
        department: 'Department of Anatomy',
        university: 'Jawaharlal Institute of Postgraduate Medical Education & Research (JIPMER), Puducherry',
        email:'editorinchiefsoma@gmail.com',
    },
    {
        id: 2,
        imageSrc: EBMmem_1_Img,
        BoardDes: 'Editorial Board Member',
        name: 'Dr. B. K. Dutta',
        degree: 'MBBS MS (Anatomy)',
        position: 'Professor',
        department: 'Department of Anatomy',
        university: 'Kalinga Institute of Medical Sciences, Bhubaneswar, Orissa',
        email:'dutta.b71@gmail.com',
    },
    {
        id: 3,
        imageSrc: EBMmem_7_Img,
        BoardDes: 'Editorial Board Member',
        name: 'Dr. Anjali Aggarwal',
        degree: 'MBBS, MD (Anatomy)',
        position: 'Professor and Head',
        department: 'Department of Anatomy',
        university: 'PGIMER, Chandigarh',
        email:'aggarwal.anjali@pgimer.edu.in',
    },
    {
        id: 4,
        imageSrc: EBMmem_2_Img,
        BoardDes: 'Editorial Board Member',
        name: 'Dr. Biswabina Ray',
        degree: 'MBBS MS (Anatomy) MBAMS',
        position: 'Professor and Head',
        department: 'Department of Anatomy',
        university: 'AIIMS, Kalyani, West Bengal',
        email:'bworldbiswa@gmail.com',
    },
    {
        id: 5,
        imageSrc: EBMmem_8_Img,
        BoardDes: 'Editorial Board Member',
        name: 'Dr Manisha R Gaikwad',
        degree: 'MBBS DGO MS (Anatomy)',
        position: 'Professor and Head',
        department: 'Department of Anatomy',
        university: 'All India Institute of Medical Sciences (AIIMS), Bhubaneswar, Odisha 751019',
        email:'manishagaikwad6710@yahoo.in',
    },
    {
        id: 6,
        imageSrc: EBMmem_4_Img,
        BoardDes: 'Editorial Board Member',
        name: 'Dr. Simmi Mehra',
        degree: 'MBBS MS (Anatomy)',
        position: 'Professor and Head',
        department: 'Department of Anatomy',
        university: 'AIIMS, Rajkot, Gujarat',
        email:'docsims27july@gmail.com',
    },
    {
        id: 7,
        imageSrc: EBMmem_3_Img,
        BoardDes: 'Editorial Board Member',
        name: 'Dr. Nidhi Puri',
        degree: 'MBBS, MS (Anatomy)',
        position: 'Professor and Head',
        department: 'Department of Anatomy',
        university: 'AIIMS, Bilaspur, Himachal Pradesh',
        email:'drnidhipuri1@gmail.com',
    },
    {
        id: 8,
        imageSrc: EBMmem_10_Img,
        BoardDes: 'Editorial Board Member',
        name: 'Dr. Seema Singh',
        degree: 'MBBS, MD (Anatomy)',
        position: 'Additional Professor',
        department: 'Department of Anatomy',
        university: 'AIIMS, New Delhi',
        email:'drseemasingh@aiims.edu',

    },
    {
        id: 9,
        imageSrc: EBMmem_6_Img,
        BoardDes: 'Editorial Board Member',
        name: 'Dr. R. Sarah',
        degree: 'MBBS MD (Anatomy)',
        position: 'Additional Professor & Head',
        department: 'Department of Anatomy',
        university: 'AIIMS, Madurai, Tamil Nadu',
        email:'drsarahsenthilkumar@gmail.com ',
    },
    {
        id: 10,
        imageSrc: EBMmem_9_Img,
        BoardDes: 'Editorial Board Member',
        name: 'Dr. Priti Chaudhary',
        degree: 'MBBS, MS (Anatomy)',
        position: 'Additional Profesor and Head',
        department: 'Department of Anatomy',
        university: 'AIIMS, Bhatinda, Punjab',
        email:'drpritiarora@gmail.com',
    },
    {
        id: 11,
        imageSrc: EBMmem_5_Img,
        BoardDes: 'Editorial Board Member',
        name: 'Dr. Prarabdha Agrawal',
        degree: 'MBBS MD (Anatomy) PGDHHM',
        position: 'Assistant Professor',
        department: 'Department of Anatomy',
        university: 'Shri Balaji Institute of Medical Sciences, Raipur, Chhattisgarh',
        email:'prarabdha_rulzz@ymail.com',
    },
];