import React from 'react';
import { Box, Image, Text } from "@quarkly/widgets";

const EditorialBoardTemplate = ({ imageSrc, boardDes, name, degree, position, department, university, email }) => {
	return (
		<Box
			display="grid"
			grid-template-columns="repeat(3, 1fr)"
			grid-gap="32px"
			md-grid-template-columns="1fr"
			width="100%"
		>
			<Box position="relative" display="flex" flex-direction="column">
				<Box
					width="100%"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					padding="100% 0px 0px 0px"
					border-radius="16px"
				>
					<Image
						src={imageSrc}
						border-radius="16px"
						width="100%"
						object-fit="contain"
						position="absolute"
						top={0}
						left={0}
						bottom={0}
						right={0}
						max-height="100%"
						srcSet=""
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
				</Box>
				<Box padding="0px 20px 0px 20px" margin="0px 0px 16px 0px">
					<Text
						margin="24px 0px 0px 0px"
						font="--headline3"
						display="block"
						text-align="center"
						color="--darkL1"
					>
						{boardDes}{" "}
						<br />
						{name}
					</Text>
					<Text
						margin="16px 0px 0px 0px"
						font="normal 500 14px/1.2 --fontFamily-sans"
						display="block"
						text-align="center"
						color="--grey"
						letter-spacing="2.8px"
					>
						{degree}
						<br />
						{position}
					</Text>
					<Text
						margin="16px 0px 0px 0px"
						font="--base"
						display="block"
						text-align="center"
						color="--darkL2"
					>
						{department}
					</Text>
					<Text
						margin="16px 0px 0px 0px"
						font="--base"
						display="block"
						text-align="center"
						color="--darkL2"
					>
						{university}
						<br />
						Email : {email}
					</Text>
				</Box>
			</Box>
		</Box>
	)
};

export default EditorialBoardTemplate;